import { useState } from 'react';
import React from 'react';
import { Link } from 'react-scroll';
import mannyg from '../../assets/mannyg.png';


import './Navbar.css'



export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);



      // setting mobile nav
      const [click, setClick] = useState(false)
      const handleClick = () => setClick(!click)
  
      //change nav when scroll
      const [color, setColor] = useState(false)
      const changeColor = () => {
        if(window.scrollY >= 650) {
          setColor(true)
        } else {
          setColor(false)
        }
      }
  
      window.addEventListener('scroll', changeColor)
        
  
      // close mobile nav
      const closeMenu = () => setClick(false)

  return (
    <div className={color ? 'header header-bg' :'header'}>
      <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full md:px-24 lg:px-8 ">
        <div class="relative flex items-center justify-between">
          <a
            href="/"
            aria-label="Company"
            title="Company"
            class="inline-flex items-center"
          >
          <img
            className= "pl-20"
            src={mannyg} 
            alt="Logo" 
            style={{ width: '500px', height: 'auto' }}
          />
          </a>

          <ul class="flex items-center hidden space-x-8 lg:flex">

            <li>
            <Link
                
                to="projects"  
                spy={true}
                smooth={true}
                offset={150}  
                duration={1000}
                className="inline-flex items-center justify-center h-12 px-6 tracking-wide text-white transition duration-200 rounded shadow-2xl bg-[#0C4190] hover:bg-[#BDE3FF] hover:text-black focus:outline-[#0C4190] focus:outline-none"
              >
                PROJECTS
              </Link>
            </li>


            <li className=''>
              <a
                href="https://github.com/Manuu1298" target="_blank" rel="noopener noreferrer"
                class="inline-flex items-center justify-center h-12 px-6 tracking-wide text-white transition duration-200 rounded shadow-2xl bg-[#0C4190] hover:bg-[#BDE3FF] hover:text-black focus:outline-[#0C4190] focus:outline-none"
                aria-label="Contactanos"
                title="Contactanos"
              >
                GITHUB
              

              </a>
            </li>

            
            <li className=''>
              <a
                href="https://www.linkedin.com/in/manuel-r-galicia-5a0640179/" target="_blank" rel="noopener noreferrer"
                class="inline-flex items-center justify-center h-12 px-6 tracking-wide text-white transition duration-200 rounded shadow-2xl bg-[#0C4190] hover:bg-[#BDE3FF] hover:text-black focus:outline-[#0C4190] focus:outline-none"
                aria-label="Contactanos"
                title="Contactanos"
              >
                LINKEDIN
              </a>
            </li>



          </ul>
          
          <div class="lg:hidden ">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none hover:bg-gray-200 focus:bg-gray-200 focus:outline-[#0C4190]"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && (
              <div class="absolute top-0 left-0 w-full">
                <div class="p-5 bg-[#BDE3FF] border rounded shadow-sm">
                  <div class="flex items-center justify-between mb-4">
                    <div>
                      <a
                        href="/"
                        aria-label="Company"
                        title="Company"
                        class="inline-flex items-center "
                      >
                        <img
                          src={mannyg} 
                          alt="Logo" 
                          style={{ width: '400px', height: 'auto' }}
                          className='justify-center'
                        />
                      </a>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:outline-[#0C4190]"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul class="space-y-4">

                      <li>
                      <Link
                          
                          to="projects"  
                          spy={true}
                          smooth={true}
                          offset={150}  
                          duration={1000}
                          className="inline-flex items-center justify-center h-12 px-6 tracking-wide text-white transition duration-200 rounded shadow-2xl bg-[#0C4190] hover:bg-[#BDE3FF] hover:text-black focus:outline-[#0C4190] focus:outline-none"
                        >
                          PROJECTS
                        </Link>
                      </li>


                      <li className=''>
                        <a
                          href="https://github.com/Manuu1298"
                          class="inline-flex items-center justify-center h-12 px-6 tracking-wide text-white transition duration-200 rounded shadow-2xl bg-[#0C4190] hover:bg-[#BDE3FF] hover:text-black focus:outline-[#0C4190] focus:outline-none"
                          aria-label="Contactanos"
                          title="Contactanos"
                        >
                          GITHUB
                        

                        </a>
                      </li>

                      
                      <li className=''>
                        <a
                          href="https://www.linkedin.com/in/manuel-r-galicia-5a0640179/"
                          class="inline-flex items-center justify-center h-12 px-6 tracking-wide text-white transition duration-200 rounded shadow-2xl bg-[#0C4190] hover:bg-[#BDE3FF] hover:text-black focus:outline-[#0C4190] focus:outline-none"
                          aria-label="Contactanos"
                          title="Contactanos"
                        >
                          LINKEDIN
                        </a>
                      </li>

                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;