import React, { useState, useEffect } from 'react';
import Spline from '@splinetool/react-spline';
import Loading from './components/DeveloperPortfolio/Loading.jsx';
import sdodyssey from './assets/gif/sdodyssey.gif';
import tajourney from './assets/gif/tajourney.gif';
import Fullapp from './components/DeveloperPortfolio/Fullapp.jsx'
import FullappTA from './components/RecruiterPortfolio/FullappTA.jsx';
import './index.css'





const HomeRecruiter = () => {

  
 const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    setTimeout(() => {
      setIsLoading(false);
    }, 10000);
  }, []);

  const [TypePage , setTypePage] = useState(false);

  return (
    <div>
      <div>
       <div className="home-container">
       <Spline scene="https://prod.spline.design/AQa6eJ6I5SHRqrtO/scene.splinecode" />
        </div>
        <div>
          
          <header className='header-for-buttons'>What would you like to learn about <span className='text-blue-portfolio'>me?</span></header>
          
          <p1>
              Click on either button and it will show you my journey!
          </p1>
  
          <hr className="mt-5 mb-5 border-gray-300 border-midpage" />
  
          <div className='button-page-switch mt-24'>
              
              <button onClick={() => setTypePage(false)} class="buttonstyle mr-24 py-8  border ">
              <img className='imagebuttonstyle' src={tajourney} alt= 'talent acquisition journey'></img>
              </button>
  
              <button onClick={() => setTypePage(true)} class="buttonstyle py-8 border ">
              <img className='imagebuttonstyle' src={sdodyssey} alt= 'software development odyssey'></img>
              </button>
          </div>
      </div>

      </div>
          <div>
            {TypePage ? 
            <div>
              {isLoading ? (<div className="loading-screen"> <p><Loading/></p> </div>  ) 
              : (  <Fullapp /> )}
            </div>
            : 
            <div>
              {isLoading ? (<div className="loading-screen"> <p><Loading/></p> </div>  ) 
              : (  <FullappTA /> )}
            </div>
          }
          </div>
            
    </div>
    
  );
};



export default HomeRecruiter;