import React, { useEffect, useState } from "react";
import "./loading.css"


function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }

const Loading = () => {

    const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
    const [shuffledSentences, setShuffledSentences] = useState([]);

    const allSentences = [
        "Deploying...",
        "Checking for bugs... ",
        "Getting another cup of coffee...",
        "Blowing crumbs out of my keyboard...",
        "Definitely not on Stackoverflow...",
        "Recompiling my life choices...",
        "Simulating productivity...",
        "Trying to figure out why this isn't working...",
        "Trying to figure out why this is working...",
        "Waiting for the Wi-Fi to load...",
        "Error 404: Motivation not found...",
        "Making algorithms cry...",
        "Calculating the meaning of life... in code.",
        "Searching for the missing semicolon...",
        "Updating status: 'Will code for pizza.'",
        "Turning caffeine into code...",
        "Finding inspiration in the console...",
        "[Insert witty loading message here]",
        "Still waiting for AI to write my code...",
        "Just Googled my own code...",
        "Deleting all the comments to make it 'optimized.'...",
        "Creating bugs to test my debugging skills...",
        "Trying to remember the syntax...",
        "Trying to remember what this function does...",
        "Trying to remember what this variable does...",
        "Trying to remember what this class does...",
        "Trying to remember what this method does...",
        "Trying to remember what this object does...",
        "Debugging by randomly changing code until it works...",
        "Trying to remember what this line does...",
 
    ];

    useEffect(() => {
        setShuffledSentences(shuffleArray(allSentences));
      }, []);
    

      useEffect(() => {
        const interval = setInterval(() => {
          setCurrentSentenceIndex((prevIndex) => (prevIndex + 1) % shuffledSentences.length);
        }, 5000);
    
        return () => {
          clearInterval(interval);
        };
      }, [shuffledSentences]);

    return (

            <div className="loader-wrapper">
                
                <div className="loader-container">
                    <div className="pb-10">
                        <span className="loader"><span className="loader-inner"></span></span>
                    </div>

                    <div className="sentence">{shuffledSentences[currentSentenceIndex]}</div>
                </div>
            </div>

    )
}

export default Loading;