import Spline from '@splinetool/react-spline';
import Vanhacklogo from '../../assets/Vanhacklogo.png'
import Surveylogo from '../../assets/Surveylogo.png'
import MRPlogo from '../../assets/MRPlogo.png'
import Urbanmoplogo from '../../assets/Urbanmoplogo.png'




const ExperienceTA = () => {

  const onButtonClick = () => {
    const pdfUrl = "CVManuelGaliciaWB.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "CVManuelGaliciaWB.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          Take a look at my {' '}
          <span className="relative inline-block">

            <span className="text-[#0C4190]">EXPERIENCE</span>
          </span>
        </h2>
        <p className="text-base text-gray-700 md:text-lg px-72">
        Curious about the specific accomplishments and projects I’ve implemented across various companies? 
        Let’s discuss how my experience can benefit your team!
        </p>
        <hr className="mt-10 border-gray-300 mb-10" />
      <div className="gap-6 row-gap-10 experience-grid">
        <div className="lg:py-6 lg:pr-16 experience-grid1">
        <div className="flex">
            <div className="flex flex-col items-center mr-4">
              <div>
                <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                  <svg
                    className="w-4 text-gray-600"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <line
                      fill="none"
                      strokeMiterlimit="10"
                      x1="12"
                      y1="2"
                      x2="12"
                      y2="22"
                    />
                    <polyline
                      fill="none"
                      strokeMiterlimit="10"
                      points="19,15 12,22 5,15"
                    />
                  </svg>
                </div>
              </div>
              <div className="w-px h-full bg-gray-300" />
            </div>
            <div className="pt-1 pb-8">
              <p className="logo-dates mb-2 text-lg font-bold">
                <a href='https://urbanmop.ca/'><img src={Urbanmoplogo} width={150} alt='UrbanMop Logo'></img></a>
                January 2024 - Present
                </p>
                <hr className="border-gray-300" />
              <p className="text-gray-700">
                <ul className='experience-list'>
                  <li className='experience-list-inner'>Successfully hired over 52 professionals across Sales, Technical, Marketing, and Customer Support roles.
                  • Developed and implemented a comprehensive training structure for both current and new employees, resulting</li>
                  <li className='experience-list-inner'>Developed and implemented a comprehensive training structure for both current and new employees, resulting in savings of over $6,000 per month.</li>
                  <li className='experience-list-inner'>Created a streamlined hiring process that increased hiring speed by 150% while reducing cost and time investment by 80%.</li>
                  <li className='experience-list-inner'>Built and integrated a team of 3 recruiters into the company, all led by myself.</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col items-center mr-4">
              <div>
                <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                  <svg
                    className="w-4 text-gray-600"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <line
                      fill="none"
                      strokeMiterlimit="10"
                      x1="12"
                      y1="2"
                      x2="12"
                      y2="22"
                    />
                    <polyline
                      fill="none"
                      strokeMiterlimit="10"
                      points="19,15 12,22 5,15"
                    />
                  </svg>
                </div>
              </div>
              <div className="w-px h-full bg-gray-300" />
            </div>
            <div className="pt-1 pb-8">
              <p className="logo-dates mb-2 text-lg font-bold">
                <a href='https://vanhack.com/'><img src={Vanhacklogo} width={150} alt='Vanhack Logo'></img></a>
                Sept 2021 - December 2023
                </p>
                <hr className="border-gray-300" />
              <p className="text-gray-700">
                <ul className='experience-list'>
                  <li className='experience-list-inner'>Sales/Recruitment environment handling Full-cycle recruitment.</li>
                  <li className='experience-list-inner'>Hired technical and non-technical personnel from various backgrounds depending on the company’s needs. (Devs, SREs, DE etc.).</li>
                  <li className='experience-list-inner'>Interviewed/Screened +1200 different candidates.</li>
                  <li className='experience-list-inner'>Generated an average of $450k in revenue per year by successfully placing high-demand technical talent.</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col items-center mr-4">
              <div>
                <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                  <svg
                    className="w-4 text-gray-600"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <line
                      fill="none"
                      strokeMiterlimit="10"
                      x1="12"
                      y1="2"
                      x2="12"
                      y2="22"
                    />
                    <polyline
                      fill="none"
                      strokeMiterlimit="10"
                      points="19,15 12,22 5,15"
                    />
                  </svg>
                </div>
              </div>
              <div className="w-px h-full bg-gray-300" />
            </div>
            <div className="pt-1 pb-8">
              <p className="logo-dates mb-2 text-lg font-bold">
                <a href='https://survey.com/'><img src={Surveylogo} width={150} alt='Vanhack Logo'></img></a>
                Jan 2019 - Sept 2021
              </p>
              <hr className="border-gray-300" />
              <p className="text-gray-700">
                <ul className='experience-list'>
                  <li className='experience-list-inner'>Managed high-volume recruitment, overseeing 30+ openings simultaneously using advanced applicant tracking systems.</li>
                  <li className='experience-list-inner'>Successfully hired over 400 workers in 18 months, significantly scaling the workforce to meet client demands.</li>
                  <li className='experience-list-inner'>Led pilot initiatives to develop data-driven recruitment strategies, improving candidate placement rates by 40%.</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col items-center mr-4">
              <div>
                <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                  <svg
                    className="w-4 text-gray-600"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <line
                      fill="none"
                      strokeMiterlimit="10"
                      x1="12"
                      y1="2"
                      x2="12"
                      y2="22"
                    />
                    <polyline
                      fill="none"
                      strokeMiterlimit="10"
                      points="19,15 12,22 5,15"
                    />
                  </svg>
                </div>
              </div>
              <div className="w-px h-full bg-gray-300" />
            </div>
            <div className="pt-1 pb-8">
              <p className="logo-dates mb-2 text-lg font-bold">
                <a href='https://www.mrpfd.com/'><img src={MRPlogo} width={150} alt='Vanhack Logo'></img></a>
                July 2017 - Jan 2019
                </p>
                <hr className="border-gray-300" />
              <p className="text-gray-700">
                <ul className='experience-list'>
                  <li className='experience-list-inner'>Hired +25 members in the tech department for technical/non-technical positions.</li>
                  <li className='experience-list-inner'>Promoted after 3 months on the job due to my pro-activity and promoted again as a Team Lead after 9 months.</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col items-center mr-4">
              <div>
                <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-440v-80h560v80H200Z"/></svg>
                </div>
              </div>
            </div>
            <div className="pt-1">
              <p className="mb-2 text-lg font-bold centerflex">UNIVERSITY</p>
              <p className="text-gray-700" />
            </div>
          </div>
        </div>
        <div className='experience-grid2 mt-16'>
        <Spline scene="https://prod.spline.design/LTMabCLUPXSqasJy/scene.splinecode" />
        </div>
      </div>

        <button className='text-gray-900 sm:text-4xl resume-button' onClick={onButtonClick}>
          Grab a copy of my <span className='text-blue-portfolio'> RESUME</span> here!
        </button>

    </div>
  );
};

export default ExperienceTA;