import Aboutme from './Aboutme.jsx';
import Projects from './Projects.jsx';
import Navbar from './Navbar.jsx';
import Video from './Video.jsx';
import Footer from './Footer.jsx';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

const Fullapp = () => {


    return (
      <div>
        
        <div>
          <Navbar />
        </div>
  
  
        <ParallaxProvider>
          <Parallax speed={-10}>
            <div id='aboutme'>
              <Aboutme />
            </div>
          </Parallax>
        </ParallaxProvider>
  
  
        <ParallaxProvider>
          <Parallax speed={-20}>
            <div id='projects'>
              <Projects />
            </div>
          </Parallax>
        </ParallaxProvider>
  
        <ParallaxProvider>
          <Parallax speed={-5}>
            <div id='video'>
              <Video />
            </div>
          </Parallax>
        </ParallaxProvider>
  
        <div>
          <Footer />
        </div>
  
      </div>
  
    );
  };
  

 export default Fullapp