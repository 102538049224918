import React from "react";
import manuportfoliovideo from '../../assets/video/manuportfoliovideo.mp4';



const Video = () => {

    return(
        
        <div className="video">
            <video className="centered-video shadow-2xl" controls="controls">
                <source src={manuportfoliovideo} type="video/mp4" />
            </video>
       </div>
    )
}

export default Video;