import NavbarTA from './NavbarTA.jsx';
import AboutmeTA from './AboutmeTA.jsx';
import ExperienceTA from './ExperienceTA.jsx'
import VideoTA from './VideoTA.jsx';
import FooterTA from './FooterTA.jsx';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

const FullappTA = () => {


    return (
      <div>
        
        <div>
          <NavbarTA />
        </div>
  
  
        <ParallaxProvider>
          <Parallax speed={0}>
            <div id='aboutme'>
              <AboutmeTA />
            </div>
          </Parallax>
        </ParallaxProvider>
  
  
        <ParallaxProvider>
          <Parallax speed={0}>
            <div id='experience'>
              < ExperienceTA />
            </div>
          </Parallax>
        </ParallaxProvider>
  
        <ParallaxProvider>
          <Parallax speed={0}>
            <div id='video'>
              
            </div>
          </Parallax>
        </ParallaxProvider>
  
        <div>
          <FooterTA />
        </div>
  
      </div>
  
    );
  };
  

 export default FullappTA